export const environment = {
    useFailOverCognito: false,
    userPoolId: 'eu-central-1_wOshtzJq2',
    userPoolWebClientId: '2hfb5osooi52nphp8avt65q0gr',
    cognitoDomain: 'momqa.auth.eu-central-1.amazoncognito.com',
    failoverUserPoolId: 'us-east-1_CqxcHD543',
    failoverUserPoolWebClientId: '67v6nskitg7k51fi7ao81vietn',
    failoverCognitoDomain: 'momfailoverqa.auth.us-east-1.amazoncognito.com',
    redirectURL: window.location.origin + '/login',
    primaryApiUrl: 'https://api.qa.mom.kochmetals.com',
    secondaryApiUrl: 'https://api.failover.qa.mom.kochmetals.com',
    primaryWebsocketUrl: 'wss://websocket.qa.mom.kochmetals.com',
    secondaryWebsocketUrl: 'wss://websocket.failover.qa.mom.kochmetals.com',
    githubCommitSha: 'e9249fa',
    githubDeploymentDate: '2025-01-24T21:38:15Z'
};
